import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';


export default class FromPage extends React.Component {
  FromTableTrainRows(from_trains_json) {
    if (from_trains_json !== undefined) {
      var trainRows = [];
      for (var i = 0; i < from_trains_json.length; i++) {
        trainRows.push(this.FromTableTrainRow(from_trains_json[i]));
      }
    }
    return trainRows;
  }

  constructor(props) {
    super(props);
    this.test1 = { test2: "test3" };
  }

  CreateCaption(syncedTime) {
    if (syncedTime.getTime() === 0) {
      return <caption style={{ color: "var(--one-color)" }}>Last Synced: Never</caption>
    } else if ((syncedTime.getTime() + 60000) > Date.now()) {
      return <caption style={{ color: "var(--one-color)" }}>Last Synced: {syncedTime.toLocaleString('en-GB')}</caption>
    } else {
      return <caption style={{ color: "var(--one-color)" }}>Last Synced: {syncedTime.toLocaleString('en-GB')}</caption>
    }
  }

  FromTableTrainRow(train_json) {
    return ([
      <TableRow>
        {this.CreateDestinationCell(train_json)}
        {this.CreatePlatformCell(train_json)}
        {this.CreateDepartureTimeCell(train_json)}
      </TableRow>
    ]);
  }

  CreateDestinationCell(train_json) {
    if (train_json.slow) {
      return <TableCell align="left">{train_json.destination}</TableCell>;
    } else {
      return <TableCell align="left">{train_json.destination} <i>Fast</i></TableCell>;
    }
  }

  CreateDepartureTimeCell(train_json) {
    if (train_json.estimated_departure === "On time") {
      return <TableCell align="right">{train_json.scheduled_departure}</TableCell>
    } else {
      return <TableCell align="right"><s>{train_json.scheduled_departure}</s> {train_json.estimated_departure}</TableCell>
    }
  }

  CreatePlatformCell(train_json) {
    return <TableCell align="center">{train_json.platform}</TableCell>
  }

  render(fromData, syncedTime, width, height, searchParams) {
    const columns = [
      { id: 'train', label: 'Destination', width: width, align: 'left', },
      { id: 'platform', label: 'Platform', width: width * 0.1, align: 'center', },
      { id: 'time', label: 'Departure', width: width * 0.2, align: 'right', },
    ];
    return [
      <Paper sx={{ width: width - 48, height: height - (48 + 48 + (height * 0.01)), overflow: 'hidden' }}>
        <TableContainer sx={{ maxHeight: height - (48 + 48 + (height * 0.01)) }}>
          <h3>Next trains from {searchParams.get("station")}</h3>
          <Table stickyHeader aria-label="sticky table">
            {this.CreateCaption(syncedTime)}
            <TableHead>
              <TableRow>
                {columns.map((column) => (
                  <TableCell
                    key={column.id}
                    align={column.align}
                    style={{ width: column.width }}
                  >
                    {column.label}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {this.FromTableTrainRows(fromData)}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    ];
  }
}