import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import SwipeableViews from 'react-swipeable-views';
import { useTheme } from '@mui/material/styles';
import AppBar from '@mui/material/AppBar';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import useWindowSize from 'react-use/lib/useWindowSize';
import Confetti from 'react-confetti';
import FrogFile from './resources/frog.svg';
import LinearProgress from '@mui/material/LinearProgress';
import { useSearchParams } from "react-router-dom";

import StationData from './resources/client_data.json';

import TowardsPage from './tabs/towards/towards.js'
import FromPage from './tabs/from/from.js'
import SettingsPage from './tabs/settings/settings.js'

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function frogFall(active, width, height) {
  if (active) {
    return (<Confetti
      width={width}
      height={height}
      numberOfPieces={15}
      gravity={0.01}
      tweenDuration={100}
      drawShape={ctx => {
        ctx.drawImage(getFrog(), 0, 0, width / 9, width / 9);
      }}
    />)
  }
}

function a11yProps(index) {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`,
  };
}

function getFrog() {
  var img = new Image();
  img.src = FrogFile;
  return img
}

function LinearDeterminate(syncedTime, progress, setProgress, width, height, setFromData, setTowardsData, setSyncedTime, syncing, setSyncing, showSyncingBar, setShowSyncingBar, searchParams) {
  useEffect(() => {
    const interval = setInterval(() => {
      if (syncedTime !== 0) {
        var milliSecondsSinceLastUpdate = (Date.now() - (Math.floor(syncedTime)));
        if (milliSecondsSinceLastUpdate > 17000) {
          if (!syncing) {
            setProgress(0);
            setSyncing(true);
            setShowSyncingBar(true);
            UpdateData(syncedTime, setFromData, setTowardsData, setSyncedTime, setSyncing, setShowSyncingBar, searchParams);
          }
        } else if (milliSecondsSinceLastUpdate < 0) {
          setProgress(0);
        } else {
          setProgress(milliSecondsSinceLastUpdate / 170);
        }
      }
    }, 100);
    return () => clearInterval(interval);
  });

  var progressBars = [<LinearProgress color="primary" variant="determinate" value={progress} sx={{ width: width, height: height * 0.01, position: "absolute", top: 48, left: 0}}/>]
  if (showSyncingBar) {
    progressBars.push(<LinearProgress sx={{ backgroundColor: "#f6ce95", width: width, height: height * 0.01, position: "absolute", top: 48, left: 0}}/>)
  }

  return (
    <Box sx={{ width: width, height: height * 0.01 }}>
      <div sx={{ position:"relative"}}>
        {progressBars}
      </div>
    </Box>
  );
}

function UpdateData(syncedTime, setFromData, setTowardsData, setSyncedTime, setSyncing, setShowSyncingBar, searchParams) {
  if (typeof searchParams !== 'undefined') {
    fetch("https://api.ontracktrains.com/" + searchParams.get("station").replace(/[^a-zA-Z]/g, '').toLowerCase())
    .then((res) => res.json())
    .then((data) => {
        setFromData(data.departing);
        setTowardsData(data.approaching);
        var thisSyncedTime = new Date(data["metadata"]["last_update"] * 1000);
        var milliSecondsSinceLastUpdate = (Date.now() - (Math.floor(thisSyncedTime)));
        if (milliSecondsSinceLastUpdate < 15000) {
          setSyncedTime(thisSyncedTime);
          setShowSyncingBar(false);
        }
        setSyncing(false);
    })
    .catch(rejected => {
        alert(rejected);
        setSyncing(false);
    });
  }
}

export default function FullWidthTabs() {
  const theme = useTheme();
  const { width, height } = useWindowSize();
  const [progress, setProgress] = useState(0);
  const [syncing, setSyncing] = useState(false);
  const [showSyncingBar, setShowSyncingBar] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();

  if (StationData.indexOf(searchParams.get("station")) === -1){
    searchParams.set("station", "Mill Hill Broadway");
    setSearchParams(searchParams);
  }
  if (["0", "1", "2"].indexOf(searchParams.get("tab")) === -1){
    searchParams.set("tab", 0);
    setSearchParams(searchParams);
  }

  const [fromData, setFromData] = useState([]);
  const [towardsData, setTowardsData] = useState({});
  const [open, setOpen] = useState([false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false, false]);
  const [syncedTime, setSyncedTime] = useState(new Date(0));

  if (searchParams.get("darkmode") === "true") {
    document.body.classList.add('dark');
  }else{
    document.body.classList.remove('dark');
  }


  useEffect((syncedTime, searchParams) => {
    UpdateData(syncedTime, setFromData, setTowardsData, setSyncedTime, setSyncing, setShowSyncingBar, searchParams);
  }, [])
  var myTowardsPage = new TowardsPage(open);
  var myFromPage = new FromPage();

  const handleChange = (event, newValue) => {
    searchParams.set("tab", newValue);
    setSearchParams(searchParams);
  };

  const handleChangeIndex = (index) => {
    searchParams.set("tab", index);
    setSearchParams(searchParams);
  };
  return (
    <Box sx={{ bgcolor: 'background.paper', width: width, height: height}}>
      {frogFall(searchParams.get("frogmode") === "true", width, height)}
      <AppBar position="static" sx={{width: width}}>
        <Tabs
          value={parseInt(searchParams.get("tab"))}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          variant="fullWidth"
          aria-label="full width tabs example"
          sx={{width: width}}
        >
          <Tab label="From" {...a11yProps(0)} sx={{width: width}}/>
          <Tab label="Towards" {...a11yProps(1)} sx={{width: width}}/>
          <Tab label="Settings" {...a11yProps(2)} sx={{width: width}}/>
        </Tabs>
      </AppBar>
      {LinearDeterminate(syncedTime, progress, setProgress, width, height, setFromData, setTowardsData, setSyncedTime, syncing, setSyncing, showSyncingBar, setShowSyncingBar, searchParams)}
      <SwipeableViews
        axis={theme.direction === 'rtl' ? 'x-reverse' : 'x'}
        index={parseInt(searchParams.get("tab"))}
        onChangeIndex={handleChangeIndex}
      
      >
        <TabPanel value={parseInt(searchParams.get("tab"))} index={0} dir={theme.direction}>
        {myFromPage.render(fromData, syncedTime, width, height, searchParams)}
        </TabPanel>
        <TabPanel value={parseInt(searchParams.get("tab"))} index={1} dir={theme.direction}>
        {myTowardsPage.render(towardsData, syncedTime, open, setOpen, width, height, searchParams)}
        </TabPanel>
        <TabPanel value={parseInt(searchParams.get("tab"))} index={2} dir={theme.direction}>
          {SettingsPage(width, height, searchParams, setSearchParams, StationData, setSyncedTime, setFromData, setTowardsData)}
        </TabPanel>
      </SwipeableViews>
      <script async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js?client=ca-pub-4165790470587646"
    crossorigin="anonymous"></script>
    </Box>
  );
}
